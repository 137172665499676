import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-toggle"
export default class FormToggleController extends Controller<HTMLElement> {
  static targets: string[] = ["checkbox", "input"];

  declare readonly hasCheckboxTarget: boolean;
  declare readonly checkboxTarget: HTMLInputElement;

  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLInputElement;
  declare readonly inputTargets: HTMLInputElement[];

  connect() {
    this.toggle();
  }

  toggle() {
    const shouldDisable = !this.checkboxTarget.checked;
    this.inputTargets.forEach((input) => {
      input.disabled = shouldDisable;
    });
  }
}
