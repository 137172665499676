import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intersection-observer"
export default class IntersectionObserverController extends Controller<HTMLElement> {
  declare observer: IntersectionObserver;
  static values = {
    continueObserving: {
      type: Boolean,
      default: true,
    },
  };

  declare readonly continueObservingValue: boolean;
  declare readonly hasContinueObservingValue: boolean;

  connect() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-viewport");

            if (!this.continueObservingValue) {
              this.observer.unobserve(entry.target);
            }
          } else {
            entry.target.classList.remove("in-viewport");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.unobserve(this.element);
  }
}
