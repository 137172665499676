import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="working-hours"
export default class WorkingHoursController extends Controller<HTMLElement> {
  static targets: string[] = ["checkbox", "workingHours"];

  declare readonly hasCheckboxTarget: boolean;
  declare readonly checkboxTarget: HTMLInputElement;

  declare readonly hasWorkingHoursTarget: boolean;
  declare readonly workingHoursTarget: HTMLElement;
  declare readonly workingHoursTargets: HTMLElement[];

  connect() {
    this.toggle();
  }

  toggle() {
    const shouldDisable = !this.checkboxTarget.checked;
    this.workingHoursTargets.forEach((workingHour) => {
      workingHour.classList.toggle("disabled", shouldDisable);
    });
  }
}
