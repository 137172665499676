import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calendar-sync-preview"
export default class CalendarSyncPreviewController extends Controller<HTMLElement> {
  static targets: string[] = ["calendar", "summary", "description"];

  declare readonly hasCalendarTarget: boolean;
  declare readonly calendarTarget: HTMLElement;

  declare readonly hasSummaryTarget: boolean;
  declare readonly summaryTarget: HTMLElement;

  declare readonly hasDescriptionTarget: boolean;
  declare readonly descriptionTarget: HTMLElement;

  colorUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateColor(inputElement);
  }

  _updateColor(inputElement: HTMLInputElement) {
    const textColorValue =
      inputElement.dataset.calendarSyncPreviewTextColorValue;
    const backgroundColorValue =
      inputElement.dataset.calendarSyncPreviewBackgroundColorValue;

    if (textColorValue && backgroundColorValue && this.hasCalendarTarget) {
      this.calendarTarget.style.color = textColorValue;
      this.calendarTarget.style.backgroundColor = backgroundColorValue;
    }
  }

  summaryUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateSummary(inputElement);
  }

  _updateSummary(inputElement: HTMLInputElement) {
    const summaryValue = inputElement.dataset.calendarSyncPreviewSummaryValue;

    if (summaryValue && this.hasSummaryTarget) {
      this.summaryTarget.textContent = summaryValue;
    }
  }

  descriptionUpdated(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    this._updateDescription(inputElement);
  }

  _updateDescription(inputElement: HTMLInputElement) {
    const descriptionValue =
      inputElement.dataset.calendarSyncPreviewDescriptionValue;

    if (descriptionValue && this.hasDescriptionTarget) {
      this.descriptionTarget.textContent = descriptionValue;
    }
  }
}
